import "./GridSection.scss"

import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import classnames from "classnames"
import { prop } from "ramda"
import { useChain, useTransition, animated } from "react-spring"

const GridSection = ({ anchor, children, className }) => {
  const childrenWithKeys = children.map((child, i) => ({
    ...child,
    key: anchor
      ? `${anchor}#${child.props.className}`
      : `${child.props.className}`,
  }))

  const initialInternalStyle = {
    opacity: 0,
    transform: "translate3d(0,40px,0)",
  }
  const transitionedInternalStyle = {
    opacity: 1,
    transform: "translate3d(0,0px,0)",
  }
  const internalTransitionsRef = useRef()
  const internalTransitions = useTransition(childrenWithKeys, prop("key"), {
    ref: internalTransitionsRef,
    trail: 400 / childrenWithKeys.length,
    from: initialInternalStyle,
    enter: transitionedInternalStyle,
    leave: initialInternalStyle,
  })

  const section = (
    <section
      id={anchor ? `${anchor}` : ""}
      className={classnames("d-flex py-3 py-sm-4 py-md-5", className)}
    >
      <Container className="py-3 py-sm-4 py-md-5">
        <div className="align-items-center section-grid-layout py-3 py-sm-4 py-md-5">
          {internalTransitions.map(({ item: { props }, props: style, key }) => (
            <animated.div key={key} style={style} {...props}></animated.div>
          ))}
        </div>
      </Container>
    </section>
  )

  const initialSectionStyle = {
    opacity: 0,
  }
  const transitionedSectionStyle = {
    opacity: 1,
  }

  const sectionTransitionsRef = useRef()
  const sectionTransition = useTransition(section, prop("key"), {
    ref: sectionTransitionsRef,
    from: initialSectionStyle,
    enter: transitionedSectionStyle,
    leave: initialSectionStyle,
  })

  useChain([sectionTransitionsRef, internalTransitionsRef], [0, 0.25])

  return sectionTransition.map(({ item, props, key }) => (
    <animated.section
      key={key}
      style={props}
      {...item.props}
    ></animated.section>
  ))
}

GridSection.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
}

export default GridSection
