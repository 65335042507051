import React from "react"
import GridSection from "@components/shared/GridSection/GridSection"
import Link from "@components/shared/Link/Link"
import { Button } from "reactstrap"
import classnames from "classnames"

export default ({
  anchor,
  data: { title, subtitle, content, cta, ctaUrl },
  image,
}) => (
  <GridSection
    anchor={anchor || "section-main"}
    className="bg-blue-light switch-on-collapse"
  >
    <div className="section-grid-copy">
      <h1
        className={classnames("mb-0 display-1 font-weight-bolder", {
          "dot dot-red": !subtitle,
        })}
      >
        {title}
      </h1>
      {subtitle ? (
        <h2 className="mb-0 display-1 font-weight-normal dot dot-red dot-small">
          {subtitle}
        </h2>
      ) : null}
      <h3 className="mt-3 mb-0 display-4">{content}</h3>
      {cta ? (
        <Link to={ctaUrl}>
          <Button color="dark" className="mt-5">
            {cta}
          </Button>
        </Link>
      ) : null}
    </div>
    <div className="section-grid-media">
      <img alt={title + " " + subtitle} src={image}></img>
    </div>
  </GridSection>
)
